import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import Panel from '../panel';
import { useTemplateLevelsQuery } from '../../../services/enumApi';
import Views from './views';
import SmallLoader from '../../small-loader';

const ViewsPanel = (props: any) => {
    const {
      currentView,
      currentViewLevel,
      moveViewLevel,
      moveError,      
      userViewOptions,      
      clientViewOptions,      
      onChangeUserView,      
      onChangeClientView,
      selectedViewId,      
      childLoading,
      onSave,
      onAdd,
      canSaveView,
      canDeleteView,
      notSaved,
      onSaveAs,
      onNewView,
      mouseWheelZoom,
      setMouseWheelZoom,
      deleteView,
      viewsLoading
    } = props; 

    const { data: ViewLevels, isLoading: boreholeStatusesIsLoading } = useTemplateLevelsQuery(true);
    const [ViewLevel, setViewLevel] = useState<any>();

    let selectedBuiltInViewIdValue = null,   
    selectedUserViewIdValue = null,
    selectedBoreholeViewIdValue = null,
    selectedProjectViewIdValue = null,
    selectedClientViewIdValue = null;

    const getSelectedViewId = () => {
      let selectedViewIdValue: any = null;      
      if (selectedViewId) {                      
        selectedViewIdValue = userViewOptions.find((o:any) => o.value == selectedViewId);        
        if (selectedViewIdValue) {
          selectedUserViewIdValue = selectedViewIdValue;
          return;
        } else {
          selectedViewIdValue = clientViewOptions.find((o:any) => o.value == selectedViewId);
        }        

        if (selectedViewIdValue) {
          selectedClientViewIdValue = selectedViewIdValue;
        }
    }};

    useEffect(() => {
      getSelectedViewId();
    },[selectedViewId]);
    

    const customStyles = {
      option: (provided: any, { data, isDisabled, isFocused, isSelected }:any) => ({
        ...provided,          
        backgroundColor: isSelected ? 'rgba(0, 54, 113, 1)' : '#FFFFFF',
        color: isDisabled
          ? '#ccc'
          : isSelected ? "white" : data.color,
        cursor: isDisabled ? 'not-allowed' : 'default',
        padding: 5,
        paddingLeft: "12px",
        "&:hover": {
          backgroundColor: "rgba(0, 54, 113, 0.3)"
        }
      })        
    };

    const ViewLevelOptions = ViewLevels?.map((t: any) => { return { label: t.name, value: t.value, isDisabled: t.name == currentViewLevel}});

    const onChangeViewLevel = (e: any) => {
      setViewLevel(e);
    };

    useEffect(() => {
      if (ViewLevels?.length) {
        setViewLevel(ViewLevels[0]?.value);
      }
    }, [ViewLevels?.length]);

    const onMoveViewLevel = () => {
      moveViewLevel(ViewLevel.value);
    };

    const anySelected = selectedBuiltInViewIdValue || selectedUserViewIdValue || selectedBoreholeViewIdValue || selectedProjectViewIdValue || selectedClientViewIdValue;

const loadingViews = viewsLoading;// !userViewOptions?.length && !clientViewOptions?.length;

return(
  <Panel title="Views" icon="View">
    <div className="panel-section">    
    {loadingViews && <div className="mt-1"><SmallLoader /> </div>}
    {!loadingViews && <>
      <div className="field">
        <label className="label">Current View</label>
        <input className='input' value={currentView?.name ?? ""} disabled/>
      </div>
      {currentViewLevel && currentViewLevel !== "Default" && <div className="field">
        <label className="label">Move Current View</label>
        <div className='columns '>
          <div className='column is-one-third'>
            From
            <div className="mt-2 has-text-weight-semibold">
              {currentViewLevel}
            </div>
          </div>
          <div className='column'>
            To
            <nav className="level">
              <div className="level-left">
                <div className="level-item">                  
                  <Select options={ViewLevelOptions} styles={customStyles} onChange={onChangeViewLevel} value={ViewLevel} classNamePrefix="select"></Select>
                </div>
                <div className="level-item">
                  <button className='button is-primary is-small' onClick={onMoveViewLevel} disabled={!anySelected}>Ok</button>
                </div>
              </div>
            </nav>
          </div>
        </div>  
        {moveError && <p className="help is-danger">{moveError}</p>}        
      </div>
      }

  <Views              
        userViewOptions={userViewOptions}        
        clientViewOptions={clientViewOptions}        
        onChangeUserView={onChangeUserView}        
        onChangeClientView={onChangeClientView}
        selectedViewId={selectedViewId}
      />

    <div>           
        <button className="button is-light mr-1 save-button" onClick={onSave} disabled={childLoading || !canSaveView || !notSaved}>
          <span className="material-icons" title="Save">
            save
          </span>&nbsp; Save
        </button>
      
        <button className="button save-button mr-1" onClick={onSaveAs} disabled={childLoading || !notSaved}>
          <span className="material-icons" title="Save as">
            save
          </span>&nbsp; Save as...
        </button>
        <button className="button save-button" onClick={deleteView} disabled={childLoading || !canDeleteView || !anySelected}>
          <img src={"/images/icons/Atlas Delete Icon.svg"} className="tab-icon" title="Delete"/>
        </button>
         <button className="button save-button mr-1 mt-2" onClick={onNewView} disabled={childLoading}>
          <span className="material-icons" title="New">
            add
          </span>&nbsp; New...
        </button>
      </div>
      </>}
    </div>
  </Panel>
)};

export default ViewsPanel;